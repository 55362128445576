
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Logo from "../../assets/rpm-logo.svg";

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div className="footer__container container">
      <div className="row">
        <div className="col-md-4">
          <Link
            to="/"
          >
            <Logo className="logo__footer" />
          </Link>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>

      </div>
      <div className="row">
        <div className="col-md-6">
          <span>Copyright &copy; {new Date().getFullYear()} {siteTitle} | All Rights Reserved</span>
        </div>
        <div className="col-md-6">
          <ul className="footer__nav__inline">
            <li><Link to="/terms" >Terms of use</Link></li>
            {/* <li><Link to="/privacy-policy" >Privacy Policy</Link></li> */}
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer