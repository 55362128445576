import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../../assets/rpm-logo.svg";
import Icon1 from "../../assets/phone.svg";
import Icon2 from "../../assets/mail.svg";
// import Icon3 from "../../assets/address.svg";
import Navigation from "../navigation/navigation";

const Header = () => {

  const [burgerState, setBurgerState] = useState('inactive');

  function toggleState(currentState) {
    switch (currentState) {
      case 'active':
        setBurgerState('inactive');
        break;
      case 'inactive':
        setBurgerState('active');
        break;
    }
  }

  return (
    <header className="header">
      <div className="header__top">
        <div className="header__top__container container">
          <div className="header__top__logo">
            <Link
              to="/"
            >
              <Logo />
            </Link>
          </div>
          <div className="header__top__contacts">
            <a className="top__contact">
                <div className="top__contact__icon">
                  <Icon1 />
                </div>
                <div className="top__contact__link">
                  <strong>027 427 9880</strong><br />
                  Request a call back
                </div>
            </a>
            <a href="mailto:office@rpmaccounting.co.nz" className="top__contact">
                <div className="top__contact__icon">
                  <Icon2 />
                </div>
                <div className="top__contact__link">
                  Write us at<br />
              office@rpmaccounting.co.nz
              </div>
            </a>
            {/* <div className="top__contact">
              <div className="top__contact__icon">
                <Icon3 />
              </div>
              <div className="top__contact__link">
                123 Sample Street, Newmarket<br />
              Auckland 1051, New Zealand
            </div>
            </div> */}
          </div>
          <div className={'header__top__navToggle ' + burgerState} onClick={e => toggleState(burgerState)} >
            <span className="header__top__navToggle__line"></span>
            <span className="header__top__navToggle__line"></span>
            <span className="header__top__navToggle__line"></span>
          </div>
        </div>
      </div>
      <div className={'header__bottom ' + burgerState}>
        <div className="header__bottom__container container">
          {/* Navigation */}
          <Navigation />
        </div>
      </div>
    </header>
  )
}

export default Header
