import React from "react"
import NavItem from "./nav-item"
import Icon1 from "../../assets/phone.svg"
import Icon2 from "../../assets/mail.svg"
import Icon3 from "../../assets/fb-icon.svg"

const Navigation = () => {
    return (<>
        <nav className="nav__primary">
            <NavItem name="Home" link="/" />
            <NavItem name="About Us" link="/about" />
            <NavItem name="Services" link="/services" />
            {/* <NavItem name="News" link="/news" /> */}
            <NavItem name="Contact Us" link="/contact" />
        </nav>
        <ul className="nav__mobile__secondary">
            <li className="nav__secondary__item">
                <a href="tel:+640274279880"><Icon1 /></a>
            </li>
            <li className="nav__secondary__item">
                <a href="mailto:office@rpmaccounting.co.nz"><Icon2 /></a>
            </li>
            <li className="nav__secondary__item">
                <a href="https://www.facebook.com/rpmaccountingnz" target="_blank"><Icon3 /></a>
            </li>
        </ul>

    </>
    )
}

export default Navigation
