import { Link } from "gatsby"
import React from "react"

const NavItem = ({name, link}) => (
    <div className="nav__item">
        <Link to={link} >
            {name}
        </Link>
    </div>
)

export default NavItem
